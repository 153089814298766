import { useApollo } from '@/composables/atoms/useApollo';
import type { PatientType, StateProvidersType } from '@/types';
import type { ApolloQueryResult } from 'apollo-client/core/types';
import { GET_PROVIDER_STATES, SET_PROVIDER_UNAVAILABLE, SET_STATE_SCREENERS } from './gql';
import { computed, defineComponent, onBeforeMount, ref, useSlots } from 'vue';
import { sort } from 'fast-sort';

export function useProviderStatesMap() {
  const providerStates = ref<StateProvidersType[]>([]);

  const { loading, query, mutate } = useApollo();

  const getProviderStates = async (search: string = '', sortBy: string = 'state'): Promise<StateProvidersType[]> => {
    try {
      const queryResult: ApolloQueryResult<{ providerStates: StateProvidersType[] }> = await query({
        fetchPolicy: 'network-only',
        query: GET_PROVIDER_STATES,
        variables: {
          search,
          sortBy,
        },
      });

      return queryResult.data?.providerStates || [];
    } catch (error: any) {
      console.log('Failed to get provider states', error.message);
    }

    return [];
  };

  const setStateScreeners = async (state: string, screeners: string[]): Promise<void> => {
    try {
      await mutate({
        fetchPolicy: 'no-cache',
        mutation: SET_STATE_SCREENERS,
        variables: {
          state,
          screeners,
        },
      });
    } catch (error: any) {
      console.log('Failed to set state screeners', error.message);
    }

    await fetchProviderStates();
    return;
  };

  const setProviderUnavailable = async (
    providerId: string,
    unavailableFrom: string,
    unavailableTo: string
  ): Promise<void> => {
    try {
      await mutate({
        fetchPolicy: 'no-cache',
        mutation: SET_PROVIDER_UNAVAILABLE,
        variables: {
          providerId,
          unavailableFrom,
          unavailableTo,
        },
      });
    } catch (error: any) {
      console.log('Failed to set provider unavailable', error.message);
    }

    await fetchProviderStates();
    return;
  };

  const fetchProviderStates = async () => {
    providerStates.value = sort(await getProviderStates()).asc((state) => state.state);
  };

  const findStateProviders = (state: string): StateProvidersType | void => {
    return providerStates.value.find((stateProvider) => stateProvider.state === state);
  };

  return {
    loading,
    providerStates,
    getProviderStates,
    setStateScreeners,
    setProviderUnavailable,
    fetchProviderStates,
    findStateProviders,
  };
}

/**
 * @example <UseProviderStatesMap v-slot="{ [addressState]: isAsync }"><div v-if="isAsync">..</UseProviderStatesMap>
 * @example <UseProviderStatesMap v-slot="stateIsAsyncMap"><div v-if="stateIsAsyncMap[addressState]">..</UseProviderStatesMap>
 */
export const UseProviderStatesMap = defineComponent({
  name: 'UseProviderStatesMap',
  setup() {
    const slots = useSlots();
    const providerStatesMap = useProviderStatesMap();

    const stateIsAsync = computed(
      (): Record<PatientType['state'], boolean> =>
        providerStatesMap.providerStates.value.reduce(
          (map, { state, modality }) => ({ ...map, [state]: /async/i.test(modality) }),
          {}
        )
    );

    onBeforeMount(async () => await providerStatesMap.fetchProviderStates());

    return () => {
      if (slots.default && !providerStatesMap.loading.value) {
        return slots.default({ ...stateIsAsync.value });
      }
    };
  },
});

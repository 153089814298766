import gql from 'graphql-tag';

const SET_PROVIDER_UNAVAILABLE = gql`
  mutation SetProviderUnavailable($providerId: UUID!, $unavailableFrom: Date, $unavailableTo: Date!) {
    markProviderUnavailable(providerId: $providerId, unavailableFrom: $unavailableFrom, unavailableTo: $unavailableTo) {
      status
    }
  }
`;

export { SET_PROVIDER_UNAVAILABLE };

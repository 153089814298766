import gql from 'graphql-tag';

const GET_PROVIDER_STATES = gql`
  query GetProviderStates($search: String, $sortBy: String) {
    providerStates(search: $search, sortBy: $sortBy) {
      state
      modality
      screeners {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export { GET_PROVIDER_STATES };

import gql from 'graphql-tag';

const SET_STATE_SCREENERS = gql`
  mutation SetStateScreeners($screeners: [UUID]!, $state: String!) {
    setStateScreeners(screeners: $screeners, state: $state) {
      status
    }
  }
`;

export { SET_STATE_SCREENERS };


import { computed, defineComponent, getCurrentInstance, ref, toRef, toRefs } from 'vue';
import { Builder } from 'builder-pattern';

import parseAsString from 'lodash/toString';

import { useRoute, useRouter } from 'vue-router/composables';

import { channelActions } from './constants';

import { getStaffer, getStafferFirstName, isStaffer, useStaffersStore } from '@/stores/staffers';

import {
  CHAT_ACTION_LIMIT,
  ChatModel,
  ChatsCacheService,
  ClientModel,
  ClientsCacheService,
  DeleteNoteRequest,
  getReadableTimeDistance,
  isAutomationBot,
  isFailedMessage,
  MessageModel,
  MessagesCacheService,
  useMessaging,
  useMessagingApi,
  useMessagingStore,
} from '~/messaging';

import { ChatMessageContextMenuPropsModel } from '@/components/ChatMessageContextMenu/models';

import { parseGraphQLErrorsAsText } from '@/tools/parseGraphQLErrorsAsText';

import type { AccountType } from '@/types';
import { MessageChannelEnum, MessageStatusEnum, ProviderActionStatusEnum } from '@/types';

import { showError } from '@/components/errors';
import { Action } from '../ChatMessageContextMenu/types';

const ChatMessage = defineComponent({
  props: {
    messageKey: {
      default: '',
      required: false,
      type: String,
    },
  },
  setup(props: { messageKey: string }) {
    const vm = getCurrentInstance?.()?.proxy;

    const router = useRouter();
    const route = useRoute();

    const contextMenuProps = ref<ChatMessageContextMenuPropsModel>(new ChatMessageContextMenuPropsModel());

    const showContextMenu = ref<boolean>(false);
    const collapsed = ref<boolean>(true);
    const checkbox = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const { messageKey } = toRefs<{ messageKey: string }>(props);

    const staffersStore = useStaffersStore();
    const currentStaffer = toRef(staffersStore, 'currentStaffer');
    const staffers = toRef(staffersStore, 'staffers');

    const messagingStore = useMessagingStore();
    const timestamp = toRef(messagingStore, 'timestamp');
    const activeChat = toRef(messagingStore, 'activeChat');
    const selectedMessages = toRef(messagingStore, 'selectedMessages');
    const isSelectionEnabled = toRef(messagingStore, 'isSelectionEnabled');
    const isAutomationMessagesFiltered = toRef(messagingStore, 'isAutomationMessagesFiltered');

    const {
      toggleSelectedMessage,
      setCurrentReplyToMessage,
      setCurrentEditMessage,
      setCurrentSubject,
      increaseChatActions,
      decreaseChatActions,
      updateChatMessage,
      deleteChatMessage,
    } = messagingStore;

    const { addOrUpdateChat } = useMessaging();
    const { updateMessagePriority, updateProviderAction, deleteNote } = useMessagingApi();

    const isDeliveryFailed = computed((): boolean => isFailedMessage(message.value.status));

    const isPriorityMessage = computed((): boolean => message.value.priority > 0);

    const isAutomationMessage = computed(
      (): boolean => !!message.value.templateName.length || isAutomationBot(message.value.authorId)
    );

    const isMessageCollapsed = computed(
      (): boolean =>
        isAutomationMessage.value &&
        !isDeliveryFailed.value &&
        ![MessageChannelEnum.NOTE, MessageChannelEnum.ACTION].includes(message.value.channel) &&
        (message.value.subject.length || message.value.shortTextBody.length) &&
        collapsed.value
    );

    const hideMessage = computed(
      (): boolean => collapsed.value && isAutomationMessage.value && isAutomationMessagesFiltered.value
    );

    const chat = computed((): ChatModel => ChatsCacheService.get(activeChat.value) || new ChatModel());
    const client = computed(
      (): ClientModel => ClientsCacheService.getById(message.value.authorId) || new ClientModel()
    );

    const message = computed((): MessageModel => MessagesCacheService.get(messageKey.value) || new MessageModel());

    const showOriginalMessageReceiver = computed((): boolean => !!message.value.originalMessageId.length);

    const originalChatName = computed((): string => {
      if (!showOriginalMessageReceiver.value) {
        return '';
      }

      if (message.value.originalMessageChatDisplayName.length) {
        return message.value.originalMessageChatDisplayName;
      }

      if (message.value.originalMessageChatId.length) {
        return `unknown (from chat: ${message.value.originalMessageChatId})`;
      }

      return `unknown (message id: ${message.value.originalMessageChatId})`;
    });

    const author = computed((): { shortName: string; fullName: string } => {
      const admin: AccountType | void = getStaffer(message.value.authorId, staffers.value);
      if (admin) {
        // this author is admin
        return { shortName: admin.firstName, fullName: admin.displayName };
      }

      if (client.value.firstName.length) {
        const shortName: string = client.value.firstName
          ? client.value.firstName.trim()
          : client.value.email || client.value.phone || client.value.id;
        const fullName: string = client.value.lastName ? `${shortName} ${client.value.lastName.trim()}` : shortName;

        return { shortName, fullName };
      }

      if (chat.value.displayName.length) {
        return { shortName: chat.value.displayNameShort, fullName: chat.value.displayName };
      }

      if (message.value.authorId.length) {
        return { shortName: message.value.authorId, fullName: '' };
      }

      return { shortName: 'Not provided', fullName: 'Not provided' };
    });

    const isAdmin = computed((): boolean => isStaffer(message.value.authorId, staffers.value));

    const messageWrapperClasses = computed((): string =>
      !isAdmin.value ? 'ChatMessageWrapper--left' : 'ChatMessageWrapper--right'
    );
    const messageClasses = computed((): string => (!isAdmin.value ? 'mr-auto ml-0' : 'ml-auto mr-0'));
    const messageHeaderClasses = computed((): string => (!isAdmin.value ? 'm-0' : 'ml-auto mr-0 justify-content-end'));
    const messageBodyClasses = computed((): string =>
      !isAdmin.value ? 'ChatMessage__body--left' : 'ChatMessage__body--right'
    );

    const messageBodyStyles = computed((): Partial<CSSStyleDeclaration> => {
      if (isDeliveryFailed.value) {
        return { backgroundColor: '#ffebe3', borderColor: '#ff4040', borderStyle: 'dotted' };
      }

      if (!isAdmin.value) {
        return { backgroundColor: '#f1f1f1', borderColor: 'rgba(222, 117, 72, 0.17)' };
      }

      switch (message.value.channel) {
        case MessageChannelEnum.ACTION:
          return { backgroundColor: '#d6ffe7', borderColor: 'rgba(141, 247, 185, 0.17)', borderStyle: 'dashed' };
        case MessageChannelEnum.NOTE:
          return { backgroundColor: '#ffecb4', borderColor: 'rgba(222, 117, 72, 0.17)', borderStyle: 'dashed' };
        case MessageChannelEnum.SMS:
        case MessageChannelEnum.EMAIL:
          return { backgroundColor: '#dae1f8', borderColor: 'rgba(0, 131, 209, 0.17)' };
        default:
          return { backgroundColor: '#FFFFFF', borderColor: 'rgba(80, 111, 137, 0.17)' };
      }
    });

    const messageFrom = computed(
      (): string => `From: ${author.value.fullName}. ${isAdmin.value ? '(admin)' : '(client)'}`
    );

    const isMessageSelected = computed((): boolean => selectedMessages.value.includes(message.value.key));

    const selectionIcon = computed((): '' | 'circle-check' | 'circle' => {
      if (!isSelectionEnabled.value) {
        return '';
      }

      return isMessageSelected.value ? 'circle-check' : 'circle';
    });

    const showCheckbox = computed((): boolean => message.value.channel === MessageChannelEnum.ACTION);

    const statusIconProps = computed(() => {
      switch (message.value.status) {
        case MessageStatusEnum.SENT:
          return { name: 'check-double', class: 'text-muted' };
        case MessageStatusEnum.READ:
          return { name: 'check-double', class: 'text-primary' };
        case MessageStatusEnum.FAILED:
        case MessageStatusEnum.CANCELED:
        case MessageStatusEnum.UNDELIVERED:
          return { name: 'triangle-exclamation', class: 'text-danger' };
      }

      return { name: 'check', class: 'text-muted' };
    });

    const channelIconProps = computed(() => {
      switch (message.value.channel) {
        case MessageChannelEnum.EMAIL:
          return { name: 'envelope' };
        case MessageChannelEnum.INAPP:
          return { name: 'comment' };
        case MessageChannelEnum.NOTE:
          return { name: 'notes-medical' };
        case MessageChannelEnum.ACTION:
          return { name: 'clipboard' };
        case MessageChannelEnum.SMS:
          return { name: 'message' };
      }

      return { name: 'question' };
    });

    const checkboxProps = computed((): Record<string, boolean> => {
      const state: boolean | null =
        loading.value || message.value.actionStatus === ProviderActionStatusEnum.PENDING
          ? null
          : message.value.actionStatus === ProviderActionStatusEnum.DONE;

      return {
        checked: message.value.actionStatus === ProviderActionStatusEnum.DONE,
        disabled: loading.value,
        indeterminate: message.value.actionStatus === ProviderActionStatusEnum.CANCELLED,
        state,
      };
    });

    const channelText = computed((): string => {
      switch (message.value.channel) {
        case MessageChannelEnum.EMAIL:
          return 'Email';
        case MessageChannelEnum.INAPP:
          return 'In-app';
        case MessageChannelEnum.ACTION:
          switch (message.value.actionStatus) {
            case ProviderActionStatusEnum.DONE:
              return 'Action completed';
            case ProviderActionStatusEnum.CANCELLED:
              return 'Action canceled';
          }

          return 'Action pending';
        case MessageChannelEnum.NOTE:
          return 'Note';
        case MessageChannelEnum.SMS:
          return 'SMS';
      }

      return 'Unknown';
    });

    const messageActionAssigneeName = computed((): string =>
      getStafferFirstName(getStaffer(message.value.actionAssigneeId, staffers.value))
    );

    const messageAuthor = computed((): string => {
      if (message.value.authorId === currentStaffer.value.id) {
        return 'You';
      }
      return !isAdmin.value ? `${author.value.shortName} (client)` : author.value.shortName;
    });

    const messageReceivedTime = computed((): string =>
      getReadableTimeDistance(message.value.createdAt, timestamp.value)
    );

    const selectMessage = (): void => {
      if (!isSelectionEnabled.value) {
        return;
      }

      toggleSelectedMessage(message.value.key);
    };

    const onCheckboxChange = async (newValue: boolean = false): Promise<void> => {
      loading.value = true;

      const oldValue: boolean = checkbox.value;
      checkbox.value = newValue;

      const newStatus: ProviderActionStatusEnum =
        message.value.actionStatus === ProviderActionStatusEnum.PENDING
          ? ProviderActionStatusEnum.DONE
          : ProviderActionStatusEnum.PENDING;

      const currentActiveChat = activeChat.value;
      const { actionId, key } = message.value;

      try {
        await updateProviderAction(actionId, newStatus);
        const newMessageModel: MessageModel | void = MessagesCacheService.update(key, { actionStatus: newStatus });
        if (!newMessageModel) {
          return;
        }

        updateChatMessage(currentActiveChat, key, newMessageModel.key);
      } catch (error) {
        showError(vm, `Failed change task ${actionId} status to ${newStatus}. ${parseGraphQLErrorsAsText(error)}`);
        checkbox.value = oldValue;
      } finally {
        loading.value = false;
      }
    };

    const bodyClicked = async (event: Event): Promise<void> => {
      const targetElement = event.target as HTMLElement;

      if (!targetElement?.hasAttribute('data-href')) {
        return;
      }

      const link: string = parseAsString(targetElement?.getAttribute('data-href'));
      if (!link.length) {
        return;
      }

      await router.replace({
        ...(route || {}),
        query: {
          ...(route?.query || {}),
          to: link,
        },
      });
    };

    const deleteMessage = async (): Promise<void> => {
      const activeChatModel: ChatModel | void = ChatsCacheService.get(activeChat.value);
      if (!activeChatModel?.id?.length) {
        console.error('close activeChatModel not found', activeChatModel.key);
        return;
      }

      // copy chat id to properly handle loading state
      const { id, updatedAt, displayName }: ChatModel = activeChatModel;

      const request: DeleteNoteRequest = Builder<DeleteNoteRequest>()
        .limit(CHAT_ACTION_LIMIT)
        .messagingNoteId(message.value.id)
        .onlyMessagesAfter(updatedAt)
        .build();

      increaseChatActions(id);

      try {
        addOrUpdateChat(await deleteNote(request));

        const currentChatKey = ChatsCacheService.getById(id)?.key || '';
        if (!currentChatKey) {
          return;
        }

        deleteChatMessage(currentChatKey, message.value.key);
      } catch (error) {
        showError(
          vm,
          `Failed to delete note in conversation with ${displayName}. ${parseGraphQLErrorsAsText(error)}`,
          50000
        );
      } finally {
        decreaseChatActions(id);
      }
    };

    const toggleImportance = async (): Promise<void> => {
      const activeChatModel: ChatModel | void = ChatsCacheService.get(activeChat.value);
      if (!activeChatModel?.id?.length) {
        console.error('close activeChatModel not found', activeChatModel.key);
        return;
      }

      // copy chat id to properly handle loading state
      const { id, displayName }: ChatModel = activeChatModel;

      increaseChatActions(id);

      try {
        // when priority set manually by person - use higher number than 1
        const newPriority: 0 | 2 = isPriorityMessage.value ? 0 : 2;
        const updatePriorityResult: boolean = await updateMessagePriority(message.value.id, newPriority);

        if (!updatePriorityResult) {
          return;
        }

        const currentChatKey = ChatsCacheService.getById(id)?.key || '';
        if (!currentChatKey) {
          return;
        }

        const newMessageModel: MessageModel | void = MessagesCacheService.update(message.value.key, {
          priority: newPriority,
        });

        if (!newMessageModel) {
          return;
        }
        updateChatMessage(currentChatKey, message.value.key, newMessageModel.key);
      } catch (error) {
        showError(
          vm,
          `Failed to update message priority in conversation with ${displayName}. ${parseGraphQLErrorsAsText(error)}`,
          25000
        );
      } finally {
        decreaseChatActions(id);
      }
    };

    const updateMessage = (): void => {
      setCurrentEditMessage(message.value?.id);
    };

    const replyToMessage = (): void => {
      setCurrentReplyToMessage(message.value.id);
      setCurrentSubject(message.value?.subject || '');
    };

    const openContextMenu = (event: PointerEvent) => {
      const actions: Action[] = (channelActions?.[message.value.channel] || []).map((action: Action) =>
        action === Action.MARK_AS_IMPORTANT && isPriorityMessage.value ? Action.MARK_AS_UNIMPORTANT : action
      );

      if (!actions.length) {
        return;
      }

      contextMenuProps.value = Builder(ChatMessageContextMenuPropsModel)
        .x(event.clientX)
        .y(event.clientY)
        .actions(actions)
        .build();

      showContextMenu.value = true;
    };

    const contextMenuAction = async (action: Action): Promise<void> => {
      switch (action) {
        case Action.DELETE:
          await deleteMessage();
          return;
        case Action.UPDATE:
          updateMessage();
          return;
        case Action.REPLY_TO:
          replyToMessage();
          return;
        case Action.MARK_AS_IMPORTANT:
        case Action.MARK_AS_UNIMPORTANT:
          await toggleImportance();
          return;
      }
    };

    return {
      showCheckbox,

      isMessageCollapsed,
      isDeliveryFailed,
      isPriorityMessage,
      isAutomationMessage,
      hideMessage,

      collapsed,
      checkbox,
      loading,

      showContextMenu,
      showOriginalMessageReceiver,

      messageWrapperClasses,
      messageClasses,
      messageHeaderClasses,
      messageBodyClasses,
      messageBodyStyles,

      message,
      author,

      originalChatName,

      messageFrom,
      messageAuthor,
      messageActionAssigneeName,

      selectionIcon,
      statusIconProps,
      channelIconProps,
      checkboxProps,
      contextMenuProps,

      channelText,
      messageReceivedTime,

      selectMessage,
      onCheckboxChange,
      bodyClicked,
      openContextMenu,
      contextMenuAction,
    };
  },
});

export default ChatMessage;


import { computed, defineComponent, nextTick, onMounted, ref, type SetupContext, toRefs } from 'vue';
import { sort } from 'fast-sort';
import { format } from 'date-fns';

import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import parseAsNumber from 'lodash/toNumber';
import parseAsString from 'lodash/toString';
import uniq from 'lodash/uniq';
import set from 'lodash/set';

import type { BTable } from 'bootstrap-vue/src/components/table';

import type { PatientType } from '@/types';

import { getReadableDate } from '~/messaging';

import { buildPropsFromModel } from '@/tools/props';

const ANSWER_MISSING: '-' = '-' as const;
const SCORE_MISSING: -1 = -1 as const;

class QuestionnaireHistoricalCardPropsModel {
  public answeredQuestionsArt: PatientType['preRenewalSurveyQuestionnaire']['answeredQuestions'] = [];
  public answeredQuestionsPrs: PatientType['artSurveyQuestionnaire']['answeredQuestions'] = [];

  public questionsArt: PatientType['artSurveyQuestionnaire']['questions'] = [];
  public questionsPrs: PatientType['preRenewalSurveyQuestionnaire']['questions'] = [];

  public hideSendSurveyButton: boolean = false;
  public scrollToTheEndOfTheTable: boolean = false;
}

const QuestionnaireHistoricalCard = defineComponent({
  emits: ['click:send-survey'],
  props: buildPropsFromModel(new QuestionnaireHistoricalCardPropsModel()),
  setup(props: QuestionnaireHistoricalCardPropsModel, { emit }: SetupContext) {
    const { answeredQuestionsArt, answeredQuestionsPrs, questionsArt, questionsPrs, scrollToTheEndOfTheTable } =
      toRefs<QuestionnaireHistoricalCardPropsModel>(props);

    const tableElement = ref<(BTable & { $el: HTMLTableElement | void }) | void>(null);

    const filter = ref<'art' | 'prs'>('art');

    const titleLabel = computed((): string => (filter.value === 'art' ? 'ART' : 'PRS'));

    const isEmpty = computed((): boolean => !allUniqueSortedSessionIds.value.length);

    const hasArtAnswers = computed((): boolean => !!answeredQuestionsArt.value?.length);
    const hasPrsAnswers = computed((): boolean => !!answeredQuestionsPrs.value?.length);

    const answeredQuestions = computed((): QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'] =>
      filter.value === 'art' ? answeredQuestionsArt.value : answeredQuestionsPrs.value
    );

    const questions = computed((): QuestionnaireHistoricalCardPropsModel['questionsArt'] =>
      filter.value === 'art' ? questionsArt.value : questionsPrs.value
    );

    const questionKeyToLongQuestion = computed(
      (): Record<string, string> =>
        Object.assign(
          {},
          ...(questions.value || []).map((question: QuestionnaireHistoricalCardPropsModel['questions'][0]) => ({
            [`${question.id}`]: parseAsString(question?.text || question?.shortText),
          }))
        )
    );

    const questionKeyToShortQuestion = computed(
      (): Record<string, string> =>
        Object.assign(
          {},
          ...(questions.value || []).map((question: QuestionnaireHistoricalCardPropsModel['questions'][0]) => ({
            [`${question.id}`]: parseAsString(question?.shortText || question?.text),
          }))
        )
    );

    const allUniqueSortedSessionIds = computed(
      (): Array<QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['session']['id']> =>
        uniq(
          sort(
            (answeredQuestions.value || [])
              .map((answeredQuestion: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]) => ({
                completedAt: answeredQuestion?.session?.completedAt || answeredQuestion?.session?.updatedAt,
                id: answeredQuestion?.session?.id,
              }))
              .filter((session) => session?.id?.length && session?.completedAt?.length)
          )
            .desc((session) => new Date(session.completedAt).getTime())
            .map((session) => session.id)
        )
    );

    const tableNames = computed((): Record<string, string> => {
      const names: Record<string, string> = {} as Record<string, string>;
      for (let i = 1; i <= allUniqueSortedSessionIds.value.length; i++) {
        const answeredQuestion: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0] | void =
          answeredQuestions.value.find(
            (answeredQuestion: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]) =>
              answeredQuestion?.session?.id === allUniqueSortedSessionIds.value[i - 1]
          );

        const at: string = answeredQuestion?.session?.completedAt || answeredQuestion?.session?.updatedAt;
        const uncompletedText: string = !answeredQuestion.session?.completedAt?.length ? '(not finished)' : '';

        const texts: string[] = [format(at, 'P'), 'at', format(at, 'p'), uncompletedText].filter(Boolean);

        names[i] = texts.join(' ');
      }

      return names;
    });

    const sortedQuestions = computed(
      (): Array<QuestionnaireHistoricalCardPropsModel['questions'][0]['id']> =>
        uniq(
          sort(
            (questions.value || [])
              .filter((question: QuestionnaireHistoricalCardPropsModel['questions'][0]) => question?.id?.length)
              .map((question: QuestionnaireHistoricalCardPropsModel['questions'][0]) => ({
                id: question.id,
                position: question?.displayOrder || -99999,
              }))
          )
            .desc((session) => session.position)
            .map((session) => session.id)
        )
    );

    const tableItems = computed(
      (): Array<
        Record<string, string> & {
          _cellVariants: Record<string, string>;
        }
      > => {
        if (isEmpty.value) {
          return [];
        }

        const emptyAnswers: typeof tableItems.value[0] = {} as typeof tableItems.value[0];
        for (let i = 1; i <= allUniqueSortedSessionIds.value.length; i++) {
          emptyAnswers[i] = ANSWER_MISSING;
        }

        return sortedQuestions.value.map((questionKey: QuestionnaireHistoricalCardPropsModel['questions'][0]['id']) => {
          const tableRow: typeof tableItems.value[0] = {
            question: questionKey,
            ...emptyAnswers,
          };

          const answerScores: Record<string, number> = {};
          allUniqueSortedSessionIds.value.forEach(
            (
              sessionId: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['session']['id'],
              index: number
            ) => {
              answerScores[index + 1] = SCORE_MISSING;

              const answeredQuestion: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0] | void =
                answeredQuestions.value.find(
                  (answeredQuestion: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]) =>
                    answeredQuestion?.session?.id === sessionId && answeredQuestion?.question?.id === questionKey
                );

              if (!answeredQuestion || !answeredQuestion?.answers?.length) {
                return;
              }

              tableRow[index + 1] = formatAnswer(questionKey, answeredQuestion.answers);
              answerScores[index + 1] = getAnswerScore(answeredQuestion?.answers?.[0]);

              if (index) {
                set(
                  tableRow,
                  `_cellVariants[${index + 1}]`,
                  getCellColor(answerScores[index + 1], answerScores[index])
                );
              }
            }
          );

          return tableRow;
        });
      }
    );

    const tableFields = computed((): { key: string; stickyColumn: boolean }[] => {
      const result: typeof tableFields['value'] = [
        {
          key: 'question',
          stickyColumn: true,
        },
      ];

      for (let i = 1; i <= allUniqueSortedSessionIds.value.length; i++) {
        result.push({
          key: parseAsString(i),
          stickyColumn: false,
        });
      }

      return result;
    });

    const columnWidths = computed((): Array<'180px' | '121px' | '21px'> => {
      return tableFields.value.map((field: typeof tableFields.value[0]) => {
        if (field.stickyColumn) {
          return '180px';
        }

        if (tableItems.value.find((item: typeof tableItems.value[0]) => item?.[field.key] !== '-')) {
          return '121px';
        }

        return '21px';
      });
    });

    const formatAnswer = (
      questionKey: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['question']['id'],
      patientAnswers: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['answers']
    ): string => {
      if (questionKey === 'tmuvsiydwr') {
        const answerText: string = getAnswerText(patientAnswers?.[0]);
        return answerText === ANSWER_MISSING ? answerText : getReadableDate(answerText) || ANSWER_MISSING;
      }

      switch (patientAnswers?.length || 0) {
        case 0: {
          return ANSWER_MISSING;
        }
        case 1: {
          return getAnswerText(patientAnswers?.[0]);
        }
      }

      return (patientAnswers || [])
        .map(
          (answers: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['answers'][0]) =>
            `• ${getAnswerText(answers)};`
        )
        .join('\n');
    };

    const getCellColor = (
      newScore: number | typeof SCORE_MISSING = SCORE_MISSING,
      previousScore: number | typeof SCORE_MISSING = SCORE_MISSING
    ): 'success' | 'danger' | '' => {
      if (newScore === SCORE_MISSING || previousScore === SCORE_MISSING || newScore === previousScore) {
        return '';
      }

      if (newScore > previousScore) {
        return 'success';
      }

      return 'danger';
    };

    const getAnswerScore = (
      answer: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['answers'][0]
    ): number => {
      return answer?.isTextField ? SCORE_MISSING : parseAsNumber(parseAsString(answer?.value)) || SCORE_MISSING;
    };

    const getAnswerText = (
      answer: QuestionnaireHistoricalCardPropsModel['answeredQuestionsArt'][0]['answers'][0]
    ): string => {
      return answer?.isTextField
        ? answer?.value || ANSWER_MISSING
        : parseAsString(answer?.placeholder || answer?.value) || ANSWER_MISSING;
    };

    const scrollToTheEndOfTable = (): void => {
      if (tableElement.value && isNumber(tableElement.value?.$el?.scrollLeft)) {
        tableElement.value.$el.scrollLeft = Number.MAX_SAFE_INTEGER;
      }
    };

    const afterMountedHook = (): void => {
      changeFilter();
      afterTableRendered();
    };

    const changeFilter = (): void => {
      if (!hasArtAnswers.value && hasPrsAnswers.value) {
        filter.value = 'prs';
        return;
      }

      filter.value = 'art';
    };

    const afterTableRendered = debounce(() => {
      if (!scrollToTheEndOfTheTable.value) {
        return;
      }

      nextTick(scrollToTheEndOfTable);
    }, 1);

    onMounted(afterMountedHook);

    const onClickRequestSurvey = (): void => {
      if (filter.value === 'art') {
        sendArtSurvey();
        return;
      }

      sendPrsSurvey();
    };

    const sendArtSurvey = (): void => {
      emit('click:send-art-survey');
    };

    const sendPrsSurvey = (): void => {
      emit('click:send-prs-survey');
    };

    return {
      titleLabel,
      filter,
      hasArtAnswers,
      hasPrsAnswers,

      tableElement,

      isEmpty,

      afterTableRendered,

      columnWidths,

      tableNames,
      tableFields,
      tableItems,

      questionKeyToLongQuestion,
      questionKeyToShortQuestion,

      onClickRequestSurvey,
      sendArtSurvey,
      sendPrsSurvey,
    };
  },
});

export default QuestionnaireHistoricalCard;

import gql from 'graphql-tag';

export const GET_DASHBOARD_STATS = gql`
  query getDashboardStats {
    dashboardStats {
      providerActionsBanner
      messagingBanner
    }
  }
`;

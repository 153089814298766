export interface LabRequisitionType {
  status: string;
  _rowVariant: string | undefined;
  updatedAt?: string;
}

export enum DeprecatedPollenRegion {
  // Old DEPRECATED regions
  i = 'Region I: CT, MA, ME, NH, NJ, NY, PA, RI, VT',
  ii = 'Region II: DC, DE, MD, NC, VA',
  iii = 'Region III: GA, northern FL, SC',
  iv = 'Region IV: FL - South of Orlando',
  v = 'Region V: IN, KY, OH, TN, WV',
  vi = 'Region VI: AL, AR, LA, MS',
  vii = 'Region VII: MI, MN, WI',
  viii = 'Region VIII: IA, IL, MO',
  ix = 'Region IX: KS, NE, ND, SD',
  x = 'Region X: OK, TX',
  xi = 'Region XI: AZ (mountains), CO, ID (mountains), MT (QUEST), NM, UT, WY',
  xii = 'Region XII: AZ (south), CA (southeast desert)',
  xiii = 'Region XIII: CA, Southern Coast',
  xiv = 'Region XIV: CA, central',
  xv = 'Region XV: ID (south), NV',
  xvi = 'Region XVI: OR, WA (central and east)',
  xvii = 'Region XVII: CA (northwest), OR (W), WA (W)',
  xviii = 'Region XVIII: Alaska',
  xix = 'Region XIX: Puerto Rico',
}

export enum PollenRegion {
  // Current regions
  mid_atlantic_coastal = 'Mid-Atlantic Coastal',
  intermountain_and_high_desert = 'Intermountain & High Desert',
  desert_southwest = 'Desert Southwest',
  eastern_mountains = 'Eastern Mountains',
  pacific_northwest = 'Pacific Northwest',
  southeastern_coastal_plain = 'Southeastern Coastal Plain',
  southern_great_plains = 'Southern Great Plains',
  northeastern_mixed_forest = 'Northeastern Mixed Forest',
  northern_great_plain = 'Northern Great Plain',
  midwest_mississippi_valley = 'Midwest Mississippi Valley',
  subtropical_florida = 'Subtropical Florida',
  coastal_and_central_california = 'Coastal & Central California',
  national_pollen_mix = 'National Pollen Mix',
}

export const pollenRegionOptions = Object.entries(PollenRegion).map(([key, value]) => ({
  text: value,
  value: key,
}));

export function getTimeZoneAbbreviation(timeZone: string, date = new Date()): string {
  try {
    if (!timeZone) {
      return timeZone;
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      timeZoneName: 'short', // Short abbreviation (e.g., PST, AKST)
    });

    const parts = formatter.formatToParts(date);
    const timeZonePart = parts.find((part) => part.type === 'timeZoneName');

    return timeZonePart ? timeZonePart.value : timeZone;
  } catch (error) {
    console.error(`Error processing time zone "${timeZone}":`, error);

    return timeZone;
  }
}

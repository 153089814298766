import gql from 'graphql-tag';
import type { PatientType } from '@/types';

export const CREATE_PRE_RENEWAL_SURVEY_SESSION = gql`
  mutation CreatePreRenewalSurveySession($patientId: UUID!) {
    createPreRenewalSurveySession(patientId: $patientId) {
      id
    }
  }
`;

export type CreatePreRenewalSurveySessionVariables = {
  patientId: PatientType['id'];
};

export type CreatePreRenewalSurveySessionResult = { id: string };

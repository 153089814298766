class ChatModel {
  public id: string = ''; //UUID
  public key: string = ''; // id+openedAt+updatedAt+closedAt+deletedAt

  public openedAt: string = '';
  public updatedAt: string = '';
  public closedAt: string = '';
  public deletedAt: string = '';

  public displayName: string = ''; // full name of chat as backends wants to display it
  public displayNameShort: string = ''; // short name of chat as backends wants to display it ([Patient name] prefers “[Nickname]“)

  public clientId: string = ''; // patient *user id*
  public patientId: string = ''; // patient id
  public latestMessageId: string = '';
  public latestClientMessageId: string = '';

  public staffersIds: string[] = []; // list of admins user ids
  public teamsIds: string[] = []; // list of teams ids
  public snoozedIds: string[] = []; // list of staffers/teams ids that chat have been snoozed for
}

export { ChatModel };

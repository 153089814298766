import { Builder } from 'builder-pattern';

import isObject from 'lodash/isObject';
import parseAsString from 'lodash/toString';
import parseAsNumber from 'lodash/toNumber';

import { formatMessageBody } from './formatMessageBody';
import { getShortMessageTextBody } from './getShortMessageTextBody';
import { getReadableDateTime } from './getReadableDateTime';

import { MessageAttachmentModel } from '../models/MessageAttachmentModel';
import { MessageModel } from '../models/MessageModel';

import { isSystemMessage } from '../validation/isSystemMessage';

import { Type } from '../types/type';

import { getKey } from '@/tools/getKey';

import type { MessageAttachmentType, MessageType } from '@/types';
import { MessageCategoryEnum, MessageChannelEnum, MessageStatusEnum, ProviderActionStatusEnum } from '@/types';

const getMessageModel = (message?: MessageType | void, key?: string): MessageModel => {
  if (!isObject(message)) {
    return new MessageModel();
  }

  const category: MessageCategoryEnum = message?.category || MessageCategoryEnum.MESSAGE;
  const channel: MessageChannelEnum = message?.channel || MessageChannelEnum.UNKNOWN;

  const type: Type = isSystemMessage(message) ? Type.SYSTEM : Type.BUBBLE;

  const createdAt: string = parseAsString(message?.createdAt);
  const updatedAt: string = parseAsString(message?.updatedAt);
  const deletedAt: string = parseAsString(message?.deletedAt);
  const sentAt: string = parseAsString(message?.sentAt);
  const priority: number = parseAsNumber(parseAsString(message?.priority));

  const messageTime: string = getReadableDateTime(createdAt);

  const authorId: string = parseAsString(message?.authorId);

  const actionId: string = parseAsString(message?.action?.id);
  const actionAssigneeId: string = parseAsString(message?.action?.assignee?.id);
  const actionStatus: ProviderActionStatusEnum = message?.action?.status || ProviderActionStatusEnum.CANCELLED;

  const subject: string = parseAsString(message?.subject);
  const templateName: string = parseAsString(message?.template?.name).trim();
  const deliveryDetails: string = parseAsString(message?.deliveryDetails);

  const originalMessageId: string = parseAsString(message?.originalMessage?.id);
  const originalMessageChatId: string = parseAsString(message?.originalMessage?.chatId);
  const originalMessageChatDisplayName: string = parseAsString(message?.originalMessage?.chatDisplayName);

  const body: string = formatMessageBody(message, type);
  const shortTextBody: string = getShortMessageTextBody(message?.text);

  const replyToId: string = parseAsString(message?.replyToId);

  const status: MessageStatusEnum =
    channel === MessageChannelEnum.ACTION && !actionAssigneeId
      ? MessageStatusEnum.FAILED
      : message?.status || MessageStatusEnum.CREATED;

  const attachments: MessageAttachmentModel[] = (message as any)?.attachments?.length
    ? (message as any)?.attachments
        .map((attachment: MessageAttachmentType) => Builder(MessageAttachmentModel, attachment).build())
        .filter((attachment: MessageAttachmentModel) => attachment?.url?.length)
    : [];

  return Builder(MessageModel)
    .id(parseAsString(message?.id))
    .key(key || getKey(message))

    .createdAt(createdAt)
    .updatedAt(updatedAt)
    .deletedAt(deletedAt)
    .sentAt(sentAt)
    .priority(priority)

    .messageTime(messageTime)

    .replyToId(replyToId)
    .authorId(authorId)
    .actionId(actionId)
    .actionAssigneeId(actionAssigneeId)

    .category(category)
    .channel(channel)
    .status(status)
    .actionStatus(actionStatus)

    .type(type)
    .channel(channel)

    .subject(subject)
    .templateName(templateName)
    .deliveryDetails(deliveryDetails)
    .shortTextBody(shortTextBody)
    .body(body)

    .originalMessageId(originalMessageId)
    .originalMessageChatId(originalMessageChatId)
    .originalMessageChatDisplayName(originalMessageChatDisplayName)

    .attachments(attachments)
    .build();
};

export { getMessageModel };
